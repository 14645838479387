import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'
import About from '../views/about-us.vue'
import Thank from '../views/thank-you.vue'
import ThankApplication from '../views/application-thank-you.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us/',
    name: 'About',
    component: About
  },
  {
    path: '/thank-you/',
    name: 'Thank',
    component: Thank
  },
  {
    path: '/application-thank-you/',
    name: 'Job Application Thank You',
    component: ThankApplication
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router