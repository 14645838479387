<template>
    <div class="w-full">
		<SiteHeader />
		<section class="w-full">
			<div class="header-section lg:flex mx-auto">
				<div class="w-full block lg:hidden header-section h-44 md:h-72"></div>
				<div class="w-full bg-dark-orange lg:w-6/12 company-profile-bg py-12">
					<div class="w-full px-6 md:px-12 lg:py-8">
						<div class="w-full text-left text-xl md:text-4xl font-bold text-black tracking-wide pb-6">
							WHAT <span class="text-white">WE DO</span>
						</div>
						<div class="w-full font-medium text-white text-md md:text-lg tracking-wide md:text-justify pb-12">
							We help businesses keep up with market trends and gain competitive advantage through qualitative market research and quantitative market research. 
							<br><br>
							Our local experts gather and thoroughly analyze data about  business operations, customer experience,  competitive landscape, and industry trends. 
							<br><br>
							We create and develop strategies based on intelligent data and  opportunities available in the market.
						</div>
						<div class="w-full leading-snug md:leading-tight text-left text-xl md:text-4xl font-bold text-black tracking-wide pb-6">
							WE DO <span class="text-white">MARKET RESEARCH FOR EVERY INDUSTRY</span>
						</div>
						<div class="w-full pb-8">
							<table cellpadding="0" cellspacing="0" class="font-medium text-white text-md md:text-lg tracking-wide">
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Consumer Packaged Goods</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Hospitals & Pharmaceuticals</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Telecommunication Companies</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Finance and Banking</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Restaurant Food Chains</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Tobacco Manufacturers</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Tobacco Manufacturers</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Travel Services</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Airlines</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Fashion and Clothing Retail Stores</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Consumer Electronics</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Home Appliances</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Media</td>
								</tr>
								<tr>
									<td><svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
									<td class="pl-2">Advertising Agencies</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="w-full py-12">
			<div class="container py-8">
				<div class="w-full m-auto">
					<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide pb-8">
						FREQUENTLY <span class="text-orange">ASKED QUESTIONS</span>
					</div>
					<div class="pb-12">
						<div class="mb-4">
							<div @click="index = 0" class="flex items-center justify-between px-8 py-4 cursor-pointer bg-white border-2 border-gray-200">
								<h6 class="text-lg font-bold text-black text:md md:text-xl">What is Consumer Insights and Market Research Analysis?</h6>
								<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 0 ? 'rotate-45' : ''">
									<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-black plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
								</div>
							</div>
							<collapse-transition>
								<div v-show="index === 0" class="p-8">
									<div class="font-medium text-gray-500 text-md md:text-lg tracking-wide pb-5">
										Understand your industry and customers. Know why your customers behave in certain ways through:
									</div>
									<div class="w-full lg:6/12 font-medium text-gray-500 text-md md:text-lg">
										<ul class="list-disc pl-6">
											<li class="pb-2">Product Usage Test</li>
											<li class="pb-2">Brand Usage, Awareness, Image Studies</li>
											<li class="pb-2">Brand Health Tracking</li>
											<li class="pb-2">Social Media Effectiveness Study</li>
											<li class="pb-2">Concept and Claims Screener</li>
											<li class="pb-2">ASC Studies / Shoppers</li>
											<li class="pb-2">BTL Conversion Studies</li>
											<li class="pb-2">In-depth One-on-One Interviews (IDI)</li>
											<li class="pb-2">Focus Group Discussions (FGD)</li>
											<li class="pb-2">Product Demo & Performance Test</li>
										</ul>
									</div>
								</div>
							</collapse-transition>
						</div>

						<div class="mb-4">
							<div @click="index = 1" class="flex items-center justify-between px-8 py-4 cursor-pointer bg-white border-2 border-gray-200">
								<h6 class="text-lg font-bold text-black text:md md:text-xl">What are Operational Audits and Mystery Shopping</h6>
								<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 1 ? 'rotate-45' : ''">
									<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-black plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
								</div>
							</div>
							<collapse-transition>
								<div v-show="index === 1" class="p-8">
									<div class="font-medium text-gray-500 text-md md:text-lg tracking-wide">
										Evaluate actual customer experience through shoppers who evaluate customer service touchpoints throughout the shopper journey. Consult our Local Experts now.
									</div>
								</div>
							</collapse-transition>
						</div>

						<div class="">
							<div @click="index = 2" class="flex items-center justify-between px-8 py-4 cursor-pointer bg-white border-2 border-gray-200">
								<h6 class="text-lg font-bold text-black text:md md:text-xl">Trade Fundamentals and Monitoring</h6>
								<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 2 ? 'rotate-45' : ''">
									<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-black plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
								</div>
							</div>
							<collapse-transition>
								<div v-show="index === 2" class="p-8">
									<div class="font-medium text-gray-500 text-md md:text-lg tracking-wide pb-5">
										Assess what your brand voice is in the market to identify growth opportunities.
									</div>
									<div class="w-full lg:6/12 font-medium text-gray-500 text-md md:text-lg">
										<ul class="list-disc pl-6">
											<li class="pb-2">Retail Audit Programs</li>
											<li class="pb-2">Share of Shelf, Share of Display, Promotion, SKU</li>
											<li class="pb-2">Out of Stock Monitoring</li>
											<li class="pb-2">Merchandising Agency Compliance</li>
											<li class="pb-2">Distributor Audits</li>
											<li class="pb-2">Account Mapping</li>
											<li class="pb-2">Competitive Intelligence & Promotions</li>
										</ul>
									</div>
								</div>
							</collapse-transition>
						</div>

					</div>
					<div class="w-60 m-auto">
						<a href="/" class="">
							<div class="w-full text-center text-md text-white font-medium tracking-wider bg-yellow-500 rounded-full hover:bg-black duration-300 px-5 py-3">Back To Home</div>
						</a>
					</div>
				</div>
			</div>
		</section>

		<section class="w-full bg-awards py-12">
			<div class="container py-8">
				<div class="w-full text-center text-xl md:text-4xl md:leading-tight font-bold text-white tracking-wide uppercase pb-4">
					Talk To Us <span class="text-orange">and Understand Your</span> <br class="hidden lg:block">Business in Today’s Dynamic Market
				</div>
				<div class="text-center font-medium text-white text-md md:text-lg tracking-wide pb-12">
					For interested clients, please send us your inquiry
				</div>
				<div class="w-full">
					<InquiryFormAbout />
				</div>
			</div>
		</section>

        <SiteFooter />
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import InquiryFormAbout from '@/components/InquiryFormAbout.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'About',
	components: {
		SiteHeader,
		InquiryFormAbout,
		SiteFooter,
		CollapseTransition
	},
	data(){
		return {
			index: 0,
		}
	},
}
</script>
<style>
	.header-section {
		background-image: url('/images/header-bg-mobile.jpg');
		background-color: #000000;
        background-position: left top !important;
		@apply bg-no-repeat;
		background-size: cover !important;
	}

	.company-profile-bg {
		background-image: url('/images/company-profile-bg.png');
        background-position: left bottom !important;
		@apply bg-no-repeat;
	}

	.bg-awards {
		background-image: url('/images/bg-dots.png');
		background-color: #000000;
        @apply bg-center;
		@apply bg-no-repeat;
	}

	.text-orange {
		color: #f1891c;
	}

	.bg-dark-orange {
		background-color: #f1891c;
	}

	@media (min-width: 768px) {
		.header-section {
			background-image: url('/images/header-bg.jpg');
			@apply bg-center;
			@apply bg-center;
			@apply bg-cover;
		}
    }

	@media (min-width: 1024px) {
		
    }

	@media (min-width: 1280px) {
		.container {
			max-width: 1280px !important;
		}

		.form-flex {
            display: flex;
		}

        .form-input-width {
            width: 50% !important;
        }
	}

	@media (min-width: 1440px) {
		
    }
</style>