<template>
    <form  @submit.prevent="submit">

        <div class="mx-2">
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.name ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="name_" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.name ? 'text-red-500' : 'text-[#072531]'">Name</label> -->
                    <input id="name" type="text"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.name" placeholder="Name" />
                    <InputError :field="errors.name" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>  
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.email ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="email" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.email ? 'text-red-500' : 'text-[#072531]'">Email</label> -->
                    <input id="email" type="email"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.email" placeholder="Email" />
                    <InputError :field="errors.email" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>   
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.contact ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="contact" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.contact ? 'text-red-500' : 'text-[#072531]'">Contact Number</label> -->
                    <input id="contact" type="tel"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.contact" placeholder="Contact Number" />
                    <InputError :field="errors.contact" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.name ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="current_location" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.name ? 'text-red-500' : 'text-[#072531]'">Company Name</label> -->
                    <input id="current_location" type="text"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.current_location" placeholder="Current Location" />
                    <InputError :field="errors.current_location" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>  
             <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.name ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="desired_position" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.name ? 'text-red-500' : 'text-[#072531]'">Company Name</label> -->
                    <input id="desired_position" type="text"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.desired_position" placeholder="Desired Position" />
                    <InputError :field="errors.desired_position" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>  
            
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-3 peer font-normal border-[1px] font-formal" :class="errors && errors.message ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="message" class="absolute top-0 left-0  font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.message ? 'text-red-500' : 'text-[#072531]'">Message</label> -->
                    <textarea id="message"  rows="4" class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.message" placeholder="Remarks:" />
                    <InputError :field="errors.message" class="absolute bottom-0 ml-2 -mt-10 text-[10px] text-red-500"/>
                </div>
            </div>

            <div class="w-full font-medium text-white text-left text-md px-2 pb-2">Upload Resume</div>
            <div class="relative w-full px-2 pb-6">
                <div class="w-full bg-gray-200 px-3 p-3">
                    <!-- <input id="files" type="file" accept="image/*" multiple /> -->
                    <input ref="input_profile" id="input_profile" type="file" class="hidden" v-on:change="onSelectFile($event)">
                    <div id="fileName"></div>
                    <input type="button" class="button-upload border-2 border-gray-800 rounded-md cursor-pointer px-2" v-on:click="$refs.input_profile.click()"
                        value="Select a file">
                    </div>
                    <InputError class="text-sm" :field="errors.profile"/>
            </div>
        </div>

        <div class="w-60 m-auto md:m-0 md:ml-auto md:py-3">
            <button type="submit" class="w-full text-center font-normal px-8 py-2 mx-auto text-lg text-white rounded-full tracking-wide btn apply-submit-btn">
                <span>SUBMIT</span>
            </button>
        </div>

    </form>
</template>

<script>
import { InputError, hapiMixins } from '@juno0424/vue2-hapi-inputs'

export default {
    mixins: [hapiMixins],
	components: {InputError},
	name: 'ApplyFormHome',
    data() {
        return {            
            endpoint: "https://hapiform.sg/api/32d5de3e-c1d7-45cf-8068-4d8a731f5e5b",
            redirectTo: "/application-thank-you/"
        }
    },
    methods: {
    onSelectFile(event) {
      let div_filename = document.querySelector('#fileName');
      let file = event.target.files[0];

      this.files.push(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // div_filename.innerText = reader.result;
        div_filename.innerText = file.name;
      };

    }
    }

}
</script>

<style>
	.apply-submit-btn {
        @apply bg-black;
        transition: 0.3s;
    }
    .apply-submit-btn:hover {
        @apply bg-yellow-800;
        text-decoration: none;
    }
</style>

