<template>
    <div class="w-full">
        <SiteHeader />
        <div class="py-16">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <h2 class="mb-12 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-montserrat font-medium">Confirmation Message</h2>
                    <p class="center font-montserrat font-medium text-lg">
                        Thank you for your kind interest in Brandworks Marketing Consulting.
                        <br>
                        We will attend to your request as soon as possible.
                    </p>
                    <div class="flex mt-12">
                        <a href="/" class="font-medium text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-yellow-500 hover:bg-black duration-300 px-8 py-2 mx-auto">Back To Home Page</a>
                    </div>
                </div>
            </div>
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue'
import SiteHeader from '@/components/SiteHeader.vue'

export default {
	name: 'Job Application Thank You',
	components: {
        SiteHeader,
        SiteFooter,
	},
	data(){
    },
    mounted () {
    }
}
</script>