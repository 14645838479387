<template>
    <div class="w-full">
		<SiteHeader />
		<section class="w-full">
			<div class="header-section mx-auto">
				<div class="container text-center">
					<div class="w-full hidden lg:block py-12"></div>
					<div class="w-full py-8"></div>
					<div class="text-lg md:text-3xl leading-snug md:leading-none text-white font-bold tracking-wider uppercase pt-12 pb-4 my-auto">
						Empowering Global Businesses
					</div>
					<div class="text-md md:text-2xl leading-snug md:leading-none text-white font-medium tracking-wider uppercase pb-4 my-auto">
						With
					</div>
					<div class="text-xl md:text-5xl leading-snug md:leading-none text-orange font-bold tracking-wider uppercase pb-12 my-auto">
						IN-DEPTH MARKET INSIGHTS <br> AND CONSUMER RESEARCH
					</div>
					<div class="w-60 m-auto pb-6">
						<a href="#contact" class="">
							<div class="w-full text-md text-white font-medium tracking-wider border-2 boder-white rounded-full hover:bg-white hover:text-yellow-500 duration-300 px-5 py-3">Inquire Now!</div>
						</a>
					</div>
					<div class="w-full py-8"></div>
					<div class="w-full hidden lg:block py-12"></div>
				</div>
			</div>
		</section>

		<section class="w-full py-12">
			<div class="container py-8">
				<div class="w-full lg:w-9/12 m-auto">
					<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide pb-6">
						WHO <span class="text-orange">WE ARE</span>
					</div>
					<div class="w-full text-center md:text-left font-medium text-gray-600 text-md md:text-lg tracking-wide justify pb-8">
						We are the leading marketing intelligence and research firm that specializes in research insights, program monitoring, and evaluating the implementation of marketing, sales and media services. 
						<br><br>
						We provide clients with accurate information on consumer insights and 3rd party agency performance that aids in strategic decision making and identifying opportunity areas for business-building.
					</div>
					<div class="w-60 m-auto">
						<a href="/about-us/" class="">
							<div class="w-full text-center text-md text-white font-medium tracking-wider bg-yellow-500 rounded-full hover:bg-black duration-300 px-5 py-3">Read More</div>
						</a>
					</div>
				</div>
				<div class="w-full py-6 md:py-12"></div>
				<div class="w-full">
					<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide pb-6">
						OUR <span class="text-orange">CORE SERVICES</span>
					</div>
					<div class="w-full md:flex">
						<div class="w-full md:w-4/12 md:mr-6 bg-gray-100">
							<div class="font-bold text-center text-white bg-yellow-500 p-2 lg:p-4">
								Operational Audits / Mystery Shopping
							</div>
							<div class="font-medium text-md text-gray-800 p-2 lg:p-4">
								<table cellpadding="0" cellspacing="0">
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Supermarkets, Public Markets, Sari-Sari</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Service Centers, Branches, Call Centers</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Events, Door-to-Door, Schools, Hospitals</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Hotels, Restaurants, Airlines</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Digital Touchpoints</td>
									</tr>
								</table>
							</div>
						</div>

						<div class="bg-gray-100 w-full md:w-4/12 md:mr-3 md:ml-3">
							<div class="font-bold text-center text-white bg-yellow-500 p-2 lg:p-4">
								Consumer Insights & Research Analysis
							</div>
							<div class="font-medium text-md text-gray-800 p-2 lg:p-4">
								<table cellpadding="0" cellspacing="0">
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Product Usage Test</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Brand Usage, Awareness, Image Studies</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Brand Health Tracking</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Social Media Effectiveness Study</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Concept and Claims Screener</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">ASC Studies / Shoppers</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">BTL Conversion Studies</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">In-depth One-on-One Interviews (IDI)</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Focus Group Discussions (FGD)</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Product Demo & Performance Test</td>
									</tr>
								</table>
							</div>
						</div>

						<div class="bg-gray-100 w-full md:w-4/12 md:ml-6">
							<div class="font-bold text-center text-white bg-yellow-500 p-2 lg:p-4">
								Operational Audits / Mystery Shopping
							</div>
							<div class="font-medium text-md text-gray-800 p-2 lg:p-4">
								<table cellpadding="0" cellspacing="0">
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Retail Audit Programs</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Share of Shelf, Share of Display, Promotion, SKU</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Out of Stock Monitoring</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Merchandising Agency Compliance</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Distributor Audits</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Account Mapping</td>
									</tr>
									<tr>
										<td><svg class="w-6 h-6" fill="none" stroke="#ff9900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></td>
										<td class="pl-2">Competitive Intelligence & Promotions</td>
									</tr>
								</table>
							</div>
						</div>

					</div>
				</div>
				<div class="w-full py-6 md:py-12"></div>
				<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide pb-6">
					OUR <span class="text-orange">CLIENTS</span>
				</div>
				<div class="w-full relative carousel-wrap">
					<carousel :autoplay="true" :nav="false" :loop="true" :dots="false" :margin="20" :items="1" :responsive="{0:{autoplay:true,items:1},768:{autoplay:true,items:4},1024:{autoplay:true,items:6}}">
						<img src="/images/clients/client01.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client02.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client03.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client04.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client05.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client06.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client07.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client08.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client09.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client10.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client11.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client12.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client13.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client14.jpg" class="mx-auto" alt="" />
						<img src="/images/clients/client15.jpg" class="mx-auto" alt="" />

						<template slot="prev">
							<span class="prev prev-btn">
								<svg fill="#fff" version="1.1" baseProfile="tiny" id="Layer_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
									xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
									width="18px" height="18px" viewBox="0 0 42 42" xml:space="preserve">
									<polygon fill-rule="evenodd" points="27.066,1 7,21.068 26.568,40.637 31.502,35.704 16.865,21.068 32,5.933 "/>
								</svg>
							</span>
						</template>

						<template slot="next">
							<span class="next next-btn">
								<svg fill="#fff" version="1.1" baseProfile="tiny" id="Layer_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
									xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
									width="18px" height="18px" viewBox="0 0 42 42" xml:space="preserve">
									<polygon fill-rule="evenodd" points="13.933,1 34,21.068 14.431,40.637 9.498,35.704 24.136,21.068 9,5.933 "/>
								</svg>
							</span>
						</template>
					</carousel>
				</div>
			</div>
		</section>


		<section id="contact" class="w-full bg-common-dark">
			<div class="w-full lg:flex">
				<div class="w-full lg:w-6/12 py-12 px-6 md:px-12">
					<div class="w-full py-8">
						<div class="w-full text-center text-xl md:text-4xl md:leading-tight font-bold text-white tracking-wide uppercase pb-4">
							FREE <span class="text-orange">CONSULTATION</span> <br class="hidden lg:block">
						</div>
						<div class="text-center font-medium text-white text-md md:text-lg tracking-wide pb-12">
							For interested clients, please send us your inquiry
						</div>
						<div class="w-full">
							<InquiryFormHome />
						</div>
					</div>
				</div>
				<div class="w-full lg:w-6/12 bg-dark-orange py-12 px-6 md:px-12">
					<div class="w-full py-8">
						<div class="w-full text-center text-xl md:text-4xl md:leading-tight font-bold text-black tracking-wide uppercase pb-4">
							BE PART OF <span class="text-white">OUR TEAM</span> <br class="hidden lg:block">
						</div>
						<div class="text-center font-medium text-white text-md md:text-lg tracking-wide pb-12">
							For job opportunities, please apply below:
						</div>
						<div class="w-full">
							<ApplyFormHome />
						</div>
					</div>
				</div>
			</div>
		</section>


		<section class="w-full py-12">
			<div class="container py-8">
				<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide pb-6">
					OUR <span class="text-orange">STRATEGY</span>
				</div>
				<div class="w-full lg:w-8/12 text-center font-medium text-gray-600 text-md md:text-lg lg:m-auto pb-6">
					We customize and tailor-fit our Market Intelligence Services depending on our client’s needs and business objectives.
				</div>
				<div class="w-full relative carousel-wrap">
					<carousel :autoplay="true" :nav="false" :loop="true" :dots="false" :margin="20" :items="2" :responsive="{0:{autoplay:true,items:1},768:{autoplay:true,items:3,margin:40},1024:{autoplay:true,items:3,margin:40}}">
						<img src="/images/strategy/strategy01.jpg" class="mx-auto" alt="" />
						<img src="/images/strategy/strategy02.jpg" class="mx-auto" alt="" />
						<img src="/images/strategy/strategy03.jpg" class="mx-auto" alt="" />
						<img src="/images/strategy/strategy04.jpg" class="mx-auto" alt="" />
						<img src="/images/strategy/strategy05.jpg" class="mx-auto" alt="" />
						<img src="/images/strategy/strategy06.jpg" class="mx-auto" alt="" />
						<img src="/images/strategy/strategy07.jpg" class="mx-auto" alt="" />

						<template slot="prev">
							<span class="prev prev-btn">
								<svg fill="#fff" version="1.1" baseProfile="tiny" id="Layer_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
									xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
									width="18px" height="18px" viewBox="0 0 42 42" xml:space="preserve">
									<polygon fill-rule="evenodd" points="27.066,1 7,21.068 26.568,40.637 31.502,35.704 16.865,21.068 32,5.933 "/>
								</svg>
							</span>
						</template>

						<template slot="next">
							<span class="next next-btn">
								<svg fill="#fff" version="1.1" baseProfile="tiny" id="Layer_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
									xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
									width="18px" height="18px" viewBox="0 0 42 42" xml:space="preserve">
									<polygon fill-rule="evenodd" points="13.933,1 34,21.068 14.431,40.637 9.498,35.704 24.136,21.068 9,5.933 "/>
								</svg>
							</span>
						</template>
					</carousel>
				</div>
			</div>
		</section>

		<section class="w-full bg-awards py-12">
			<div class="container py-8">
				<div class="w-full text-center text-xl md:text-4xl font-bold text-white tracking-wide pb-10">
					OUR <span class="text-orange">AWARDS</span>
				</div>
				<div class="w-full lg:flex">
					<div class="w-full lg:6/12 font-medium text-white text-lg md:text-xl pb-8">
						<ul class="list-disc pl-6">
							<li class="pb-4">P&G Global Partner Excellence Awards – Top 85 supplier globally</li>
							<li class="pb-4">P&G BrandOps Best Service Provider FY 10-11</li>
							<li class="pb-4">Special Award for Service Excellence</li>
							<li class="pb-4">Agency-of-Record (AOR) for Quality Assurance & Controls</li>
						</ul>
					</div>
					<div class="w-full lg:6/12">
						<img style="max-width: 420px;" src="/images/img-awards.jpg" class="w-full mx-auto lg:pl-10" alt="Our Awards" />
					</div>
				</div>
			</div>
		</section>

        <SiteFooter />
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import InquiryFormHome from '@/components/InquiryFormHome.vue'
import ApplyFormHome from '@/components/ApplyFormHome.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		InquiryFormHome,
		ApplyFormHome,
		SiteFooter,
		CollapseTransition,
		carousel
	}
}
</script>
<style>
	.header-section {
		background-image: url('/images/header-bg-mobile.jpg');
		background-color: #000000;
        @apply bg-center;
        @apply bg-top;
		@apply bg-no-repeat;
		background-size: cover !important;
	}

	.bg-awards {
		background-image: url('/images/bg-dots.png');
		background-color: #000000;
        @apply bg-center;
        @apply bg-center;
		@apply bg-no-repeat;
	}

	.text-orange {
		color: #f1891c;
	}

	.bg-dark-orange {
		background-color: #f1891c;
	}

	.bg-gray-800 {
		background-color: #292929 !important;
	}

	@media (min-width: 768px) {
		.header-section {
			background-image: url('/images/header-bg.jpg');
			@apply bg-center;
			@apply bg-center;
			@apply bg-cover;
		}
    }

	@media (min-width: 1024px) {
		
    }

	@media (min-width: 1280px) {
		.container {
			max-width: 1280px !important;
		}
	}

	@media (min-width: 1440px) {
		
    }
</style>