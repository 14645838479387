<template>
    <form  @submit.prevent="submit">

        <div class="mx-2">
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.name ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="name_" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.name ? 'text-red-500' : 'text-[#072531]'">Name</label> -->
                    <input id="name" type="text"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.name" placeholder="Name" />
                    <InputError :field="errors.name" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>  
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.name ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="company_name" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.name ? 'text-red-500' : 'text-[#072531]'">Company Name</label> -->
                    <input id="company_name" type="text"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.company_name" placeholder="Company Name" />
                    <InputError :field="errors.company_name" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>       
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.contact ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="contact" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.contact ? 'text-red-500' : 'text-[#072531]'">Contact Number</label> -->
                    <input id="contact" type="tel"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.contact" placeholder="Contact Number" />
                    <InputError :field="errors.contact" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.email ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="email" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.email ? 'text-red-500' : 'text-[#072531]'">Email</label> -->
                    <input id="email" type="email"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.email" placeholder="Email Address" />
                    <InputError :field="errors.email" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>
            <div class="relative w-full px-2">
                <div class="relative mb-3 peer font-normal border-[1px] font-formal" :class="errors && errors.message ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="message" class="absolute top-0 left-0  font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.message ? 'text-red-500' : 'text-[#072531]'">Message</label> -->
                    <textarea id="message"  rows="4" class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.message" placeholder="Inquiry" />
                    <InputError :field="errors.message" class="absolute bottom-0 ml-2 -mt-10 text-[10px] text-red-500"/>
                </div>
            </div>
        </div>

        <div class="w-60 m-auto md:m-0 md:ml-auto py-3">
            <button type="submit" class="w-full text-center font-normal px-8 py-2 mx-auto text-lg text-white rounded-full tracking-wide btn submit-btn">
                <span>SUBMIT</span>
            </button>
        </div>

    </form>
</template>

<script>
import { InputError, hapiMixins } from '@juno0424/vue2-hapi-inputs'

export default {
    mixins: [hapiMixins],
	components: {InputError},
	name: 'InquiryFormHome',
    data() {
        return {            
            endpoint: "https://hapiform.sg/api/76305b83-7711-4d1a-9672-feb8523428c0",
            redirectTo: "/thank-you/"
        }
    }
}
</script>

<style>
	.submit-btn {
        @apply bg-yellow-600;
        transition: 0.3s;
    }
    .submit-btn:hover {
        @apply bg-black;
        text-decoration: none;
    }
</style>

